<template>
    <div class="CharMax">
        <!-- 头部 margin-right:50vh;-->
        <div class="CharTouBu">
            <!-- 按钮 -->
            <img @click="GoHome" src="../../../assets/ConstructionLandscape/Home.png" alt=""
                style="height:70%;cursor: pointer;margin-right:15vw;">
            <!-- 标题 -->
            <div class="CharNavTitle">
                <div>红色征集</div>
            </div>
            <!-- 按钮 margin-left:50vh-->
            <img @click="GoLogin" src="../../../assets/ConstructionLandscape/GeRen.png" alt=""
                style="height: 70%;;cursor: pointer;margin-left:15vw">
        </div>
        <!-- 面包屑 -->
        <div class="CharCrumbs">
            <div><span>当前位置：</span><span style="cursor: pointer;" @click="culture()">红色文化馆</span></div>
            <div style="height:100%;display: flex;align-items: center;"> <img
                    src="../../../assets/RedCultureCenter/arrows.png" alt=""
                    style="height:50%;margin-top:0.2vh;margin-left:1vh;"></div>
            <div style="margin-left: 1vh;"><span style="cursor: pointer;">红色征集</span></div>
            <div style="height:100%;display: flex;align-items: center;"> <img
                    src="../../../assets/RedCultureCenter/arrows.png" alt=""
                    style="height:50%;margin-top:0.2vh;margin-left:1vh;"></div>
            <div style="margin-left: 1vh;"><span style="cursor: pointer;">红色影像</span></div>
        </div>
        <!-- 输入框 -->
        <div class="CharBox">
            <!-- 下拉框 -->
            <div class="CharDrop">
                <div class="wrap" style="width: 100%;">
                    <div class="dark-select">
                        <el-select v-model="options[1].label" :popper-append-to-body="false" style="width:100%;height:100%">
                            <el-option v-for="(item, index) in options" :key="index" :label="item.label" :value="item.value"
                                class="provinces_select" @click="shadow(item.value)">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <!-- 下面 -->
            <div class="RedBelow">
                <!-- 影像标题 -->
                <div class="TheTitle">
                    <div class="TheHeadline">影像标题：</div>
                    <!-- 输入框 -->
                    <input type="text" v-model="CharForm.title">
                </div>
                <!-- 所属景区： -->
                <div class="TheTitle">
                    <div class="TheHeadline">所属景区：</div>
                    <!-- 下拉框 -->
                    <el-select v-model="CharForm.scenic" placeholder="请选择" style="width:91%;height:72%;">
                        <el-option v-for="(item, index) in ScenicArea" :key="index" :label="item.scenicName"
                            :value="item.scenicId" class="provinces_select" @click="ScenicId(item.scenicId)">
                        </el-option>
                    </el-select>
                </div>
                <!-- 上传视频 -->
                <div class="TheUploading">
                    <!-- 上传视频/图片： -->
                    <div class="TheCharacters">
                        <div class="ThePictureOne">上传视频：</div>
                        <div style="font-size:1.8vh;color:rgba(153, 153, 153, 1);">（限制一个视频）</div>
                    </div>
                    <!-- 图片 商品 -->
                    <div class="TheFlex">
                        <!-- 上传视频 -->
                        <div class="TheVideo">

                            <el-upload action="http://ccsu.lzxx8848.com/api/tools/fileUpload/videoUpload"
                                :limit="limitCountImgOne" class="dl-avatar-uploader-min square"
                                :class="{ uoloadBtn: showBtnDealImgOne, disUoloadBtn: noneBtnImgOne }"
                                :on-change="onChangeUploadOne" list-type="picture-card" :file-list="FileListOne"
                                :headers="headers" :data="VideoData" :on-preview="handlePreviewOne"
                                :on-exceed="handleExceedCover" :before-upload="beforeImageUploadOne"
                                :on-remove="coverFileRemoveOne" :on-success="coverFileSuccessOne">
                                <div style="width:100%;height:100%;"> <img
                                        src="../../../assets/PersonalCenter/collect/uploading.png" alt=""
                                        style="width:100%;height:100%;"> </div>
                            </el-upload>

                            <el-dialog v-model="dialogVisibleOne">
                                <video controls="controls" style="width: 100%;">
                                    <source :src="dialogImageUrlOne" type="video/mp4" />
                                </video>
                                <!-- <video :src="dialogImageUrlOne" class="video" controls="controls" ></video> -->
                                <!-- <img w-full class="image onsite-img" :src="dialogImageUrl" /> -->
                            </el-dialog>
                        </div>
                        <!-- 提示 -->
                        <div style="font-size:1.8vh;color: rgba(153, 153, 153, 1);margin-left:1.3vw;">(限制上传1mb)</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 提交按钮 -->
        <div @click="TheSubmit()"
            style="width:8%;height:4.5%;background-color:rgba(41, 98, 182, 1);display: flex;justify-content: center; align-items: center;margin: 0 auto;margin-top:2vh;color: rgba(255, 255, 255, 1);cursor: pointer;font-size:2vh;">
            保存</div>
    </div>
</template>

<script>
import { time } from 'console';
export default {
    data() {
        return {
            CharForm: {
                // 景区
                scenic: '',
                // 标题
                title: '',
            },
            //查景区
            ScenicArea: [],
            id: '',
            headers: { Authorization: 'Bearer ' + this.$cookies.get("token") },
            //  上传视频
            // 视频上传
            videoList: '',
            dialogImageUrlOne: '',
            limitCountImgOne: 1,
            showBtnDealImgOne: true,
            noneBtnImgOne: false,
            dialogVisibleOne: false,
            VideoData: {
                useType: 'redCultureVideo',
                videoFile: '',
            },
            options: [
                {
                    value: 1,
                    label: "红色人物",
                },
                {
                    value: 2,
                    label: "红色影像",
                },
            ],
        }
    },
    mounted() {
        this.FindScenic()
    },
    methods: {
        // 返回个人中心
        GoLogin() {
            this.$router.push('/login');
        },
        // 返回首页
        GoHome() {
            this.$router.push('/');
        },
        //返回红色文化馆
        culture() {
            this.$router.push('/RedCultureCenter');
        },
        //点击跳转红色影像征集
        shadow(value) {
            if (value == 1) {
                this.$router.push('/CharactersSubmit');
            }
        },
        // 上传视频
        onChangeUploadOne(file, FileListOne) {
            this.VideoData.videoFile = file.raw
            this.noneBtnImgOne = FileListOne.length >= this.limitCountImgOne;
        },
        beforeImageUploadOne(rawFile,) {
            if (rawFile.size / 1024 / 1024 > 30) {
                this.$message.error("单个视频大小不能超过30MB!");
                return false;
            }
            if ([
                "video/mp4",
                "video/ogg",
                "video/flv",
                "video/avi",
                "video/wmv",
                "video/rmvb",
                "video/mov",
            ].indexOf(rawFile.type) == -1     //控制格式
            ) {
                this.$message.error("请上传正确的视频格式!");
                return false;
            }
            return true;
        },
        coverFileSuccessOne(response, file, FileListOne) {

            if (response.code === 200) {
                let obj = {};
                obj.name = file.name;
                obj.url = response.data;
                // this.videoList.push(obj);
                this.videoList = response.data.fileUrl
                this.$message({
                    message: '上传成功',
                    type: 'success'
                });
            } else {
                this.$message.error(`视频上传失败`)
            }
            if (response.code === 401) {
                this.$message({
                    message: '请先登录',
                    type: 'warning'
                });
                this.$router.push('/login');
            }
        },


        coverFileRemoveOne(file, FileListOne) {
            this.videoList.forEach((item, index) => {
                if (file.name == item.name) {
                    this.videoList.splice(index, 1);
                }
            });
            this.noneBtnImgOne = FileListOne.length >= this.limitCountImgOne;
        },
        handleExceedCoverOne(files, FileListOne) {
            this.$message.error({
                message: `上传视频数量超出限制！`,
                type: "error",
            });
        },
        handlePreviewOne(uploadFile) {
            this.dialogImageUrlOne = uploadFile.url;
            this.dialogVisibleOne = true;
        },
        // 查所以景区.
        FindScenic() {
            var axios = require('axios');
            var config = {
                method: 'get',
                url: this.$Schttp + '/vtp/app/scenic/scenicIndex',
                headers: {
                    Authorization: "Bearer " + this.$cookies.get("token"),
                    "Content-Type": "application/json",
                },

            };
            axios(config)
                .then((res) => {
                    if (res.data.code == 200) {
                        this.ScenicArea = res.data.data
                    }
                })
                .catch(function (error) {
                });
        },
        // 点击查id
        ScenicId(id) {
            this.id = id
        },
        // 点击提交
        TheSubmit() {
            if (this.CharForm.title == '') {
                this.$message({
                    message: '标题不可为空',
                    type: 'error'
                });
                return
            }
            if (this.CharForm.scenic == '') {
                this.$message({
                    message: '所属景区不可为空',
                    type: 'error'
                });
                return
            }
            if (this.videoList == '') {
                this.$message({
                    message: '视频不可为空',
                    type: 'error'
                });
                return
            }
            var axios = require('axios');
            var config = {
                method: 'post',
                url: this.$Schttp + '/vtp/app/redCultureVideo/save',
                headers: {
                    Authorization: "Bearer " + this.$cookies.get("token"),
                    "Content-Type": "application/json",
                },
                data: {
                    scenicId: this.id,
                    title: this.CharForm.title,
                    videoUrl: this.videoList,
                }
            };
            axios(config)
                .then((res) => {
                    if (res.data.code == 200) {
                        this.$message({
                            message: '提交成功',
                            type: 'success'
                        });
                        this.$router.push('/RedCultureCenter');
                    } else {
                        this.$message({
                            message: '提交失败',
                            type: 'error'
                        });
                    }

                })
                .catch(function (error) {
                });
        }
    }
}
</script>

<style lang="less" scoped>
* {
    padding: 0;
    margin: 0;
}

.CharMax {
    width: 100%;
    height: 100%;
    background-image: url('../../../assets/RedCultureCenter/RedCultureCenterQuanBG.png');
    background-size: 100% 100%;
    color: rgb(22, 245, 255, );

    .CharTouBu {
        width: 100%;
        height: 14%;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .CharNavTitle {
            margin-top: -7vh;
            font-size: 4vh;
            font-weight: 1000;
            margin-right: 4vh;
            text-align: center; //水平居中
            // text-shadow: 0 0 2px rgb(0, 187, 255), 0 0 10px rgb(0, 204, 255), 0 0 10px rgb(0, 213, 255), 0 0 10px rgb(0, 213, 255); //设置发光效果
            color: #7D97F1;
        }
    }

    .CharCrumbs {
        width: 20%;
        height: 3%;
        margin-left: 10.3vh;
        color: rgba(221, 221, 221, 1);
        font-size: 1.6vh;
        display: flex;
        align-items: center;
    }

    .CharBox {
        width: 93%;
        height: 70%;
        margin: 0 auto;
        margin-top: 3vh;
        background-image: url(../../../assets/IntangibleCultural/collect/zhongjuxing.png);
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        flex-direction: column;

        .CharDrop {
            width: 90%;
            height: 9%;
            margin-top: 5vh;
            display: flex;
            align-items: center;
            border: 1px solid rgba(100, 162, 255, 1);

            .dark-select {

                // 修改input默认值颜色 兼容其它主流浏览器
                /deep/ input::-webkit-input-placeholder {
                    color: rgba(255, 255, 255, 1);
                }

                /deep/ input::-moz-input-placeholder {
                    color: rgba(255, 255, 255, 1);
                }

                /deep/ input::-ms-input-placeholder {
                    color: rgba(255, 255, 255, 1);
                }

            }

            /deep/ .el-input__inner {
                background-color: rgba(255, 255, 255, 0);
                color: rgba(255, 255, 255, 1);
                border: none; // 去掉边框
                text-align: center;
            }

            // input框 右侧的箭头
            /deep/ .el-select .el-input .el-select__caret {
                width: 3.5vh;
                height: 2vh;
                color: rgba(100, 162, 255, 1);
                font-size: 2.5vh;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 1.9vh;
                margin-right: 2vh;
            }

        }

        .RedBelow {
            width: 90%;
            height: 82%;

            .TheTitle {
                width: 100%;
                height: 12%;
                display: flex;
                align-items: center;
                border-bottom: 1px solid rgba(100, 162, 255, 1);
                margin-top: 4vh;

                .TheHeadline {
                    color: rgba(255, 255, 255, 1);
                    font-size: 1.8vh;
                    margin-left: 3vh;
                }

                input {
                    width: 90%;
                    -webkit-text-fill-color: rgba(255, 255, 255, 1); //颜色是设置成你需要的颜色
                    outline: none;
                    background-color: rgba(0, 0, 0, 0);
                    border: 0px;
                    text-indent: 2vh;
                    padding: 1vh;
                    font-size: 1.8vh;
                    color: rgba(255, 255, 255, 1);
                }

                // 修改input默认值颜色 兼容其它主流浏览器
                /deep/ input::-webkit-input-placeholder {
                    color: rgba(255, 255, 255, 1);
                }

                /deep/ input::-moz-input-placeholder {
                    color: rgba(255, 255, 255, 1);
                }

                /deep/ input::-ms-input-placeholder {
                    color: rgba(255, 255, 255, 1);
                }

                // input框
                /deep/ .el-select,
                /deep/ .el-input,
                /deep/ .el-input__inner {
                    width: 100%;
                    background-color: rgba(255, 255, 255, 0);
                    color: rgba(255, 255, 255, 1);
                    border: none; // 去掉边框
                    font-size: 1.8vh;
                    font-weight: 300;
                }

                /deep/ .el-select .el-input .el-select__caret {
                    color: rgba(100, 162, 255, 1);
                }

                // option选项 最外层
                /deep/ .el-select-dropdown {
                    border: none !important;
                    background: rgba(255, 255, 255, 1) !important;
                    z-index: 9999;
                }
            }

            .TheUploading {
                width: 100%;
                height: 30%;
                display: flex;
                margin-top: 4vh;
                margin-left: 3vh;

                .TheCharacters {
                    width: 12%;
                    font-size: 1.8vh;
                    color: rgba(255, 255, 255, 1);
                    height: 100%;

                    .ThePictureOne {
                        width: 100%;
                        height: 27%;
                    }

                }

                .TheFlex {
                    width: 72%;
                    height: 100%;

                    .TheVideo {
                        width: 100%;
                        height: 100%;
                        display: flex;

                        /deep/ .el-upload {
                            width: 9vw;
                            height: 100%;
                            background-color: rgba(255, 255, 255, 0);
                            border: none;
                            border: 0px;
                        }

                        ::v-deep .disUoloadBtn .el-upload--picture-card {
                            display: none;
                            /* 上传按钮隐藏 */
                        }

                        ::v-deep .el-upload-list--picture-card .el-upload-list__item {
                            // margin-top: 4%;
                            width: 9vw;
                            height: 17vh;
                        }

                        // /deep/ 后要加一个空格

                        /deep/ .el-dialog {
                            width: 70%;
                            height: 70%;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            .video {
                                // width: 20vw;
                                width: 100%;
                                height: 100%;
                            }

                        }
                    }
                }
            }
        }
    }

}</style>